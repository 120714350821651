
import { ROOT_ACTIONS } from '@/store/actions';
import { User } from '@/store/user/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgramUserCard extends Vue {
  @Prop({ required: true }) user!: User;
  @Prop({ required: true }) title!: string;
}
